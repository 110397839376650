import React, { useState, createContext, useContext } from 'react'
import Api from 'utils/api'

const initAgencyContextPropsState = {
  agency: undefined,
  setAgency: () => {},
  getAgency: async (selected_location) => {},
}

const AgencyContext = createContext(initAgencyContextPropsState)

const useAgency = () => {
  return useContext(AgencyContext)
}

const AgencyProvider = ({ children }) => {
  const [agency, setAgency] = useState({})

  const getAgency = async (selected_location) => {
    const { response } = await Api(
      '/location/agency?location_id=' + selected_location,
      'get'
    )
    console.log(response,'faraz')
    if (response) setAgency(response.data)
    else setAgency({})
  }

  return (
    <AgencyContext.Provider
      value={{
        agency,
        setAgency,
        getAgency,
      }}
    >
      {children}
    </AgencyContext.Provider>
  )
}

export { AgencyProvider, useAgency }
