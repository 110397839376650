import { ErrorMessage, useFormikContext } from 'formik'
import React, { useEffect } from 'react'

function InputFieldOutlined({
  label,
  name,
  placeholder,
  type,
  readOnly,
  required,
  StartIcon,
  EndIcon,
  value,
  onChange,
  autoFocus,
  Ref,
  labelClass,
  inputClass,
}) {
  const formik = useFormikContext()
  const props = name ? formik?.getFieldProps(name) : {}

  useEffect(() => {
    const inputField = document.getElementById(name) // Assuming 'name' is the ID for the input field

    const preventScroll = (event) => {
      if (inputField && inputField.type === 'number') {
        event.preventDefault()
      }
    }

    // Attach event listener to prevent default scroll behavior on number input fields
    if (inputField) {
      inputField.addEventListener('wheel', preventScroll, { passive: false })

      return () => {
        // Clean up event listener on unmount
        inputField.removeEventListener('wheel', preventScroll)
      }
    }
  }, [name])

  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className={`block mb-2 text-sm font-medium text-gray-900 ${labelClass}`}
        >
          {label}
          {required ? ' *' : ''}
        </label>
      )}
      <div className="relative w-full">
        {StartIcon && (
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            {StartIcon}
          </div>
        )}
        <input
          ref={Ref}
          id={name}
          className={
            inputClass +
            ` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ${
              readOnly
                ? 'focus:outline-none focus:bg-gray-50'
                : 'focus:ring-blue-500 focus:bg-white focus:border-blue-500'
            } block w-full ${StartIcon ? 'ps-10' : ''} ${
              EndIcon ? 'pe-10' : ''
            } p-2.5`
          }
          type={type || 'text'}
          autoFocus={autoFocus}
          placeholder={placeholder || ''}
          value={value}
          onChange={onChange}
          {...props}
          readOnly={readOnly}
        />
        {EndIcon && (
          <div className="absolute inset-y-0 end-0 flex items-center pe-3">
            {EndIcon}
          </div>
        )}
      </div>
      {name && (
        <ErrorMessage
          name={name}
          component={'div'}
          className="text-xs text-red-500 font-light mt-1"
        />
      )}
    </div>
  )
}

export default InputFieldOutlined
